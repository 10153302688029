import React from "react";
import { graphql } from "gatsby";
import Cases from "../../components/Cases";

export default (props) => <Cases {...props} />;

export const pageQuery = graphql`
  query CasesFRQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "case-page-fr" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "MMMM DD, YYYY")
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            clientlogo {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(
      frontmatter: {
        templateKey: { eq: "cases-overview" }
        languageKey: { eq: "fr" }
      }
    ) {
      frontmatter {
        heading
        subheading
        cta
      }
    }
  }
`;
